<template>
<v-card class="goal-item">
    <v-expansion-panels v-model="isExpansionToggle" :disabled="isDisableKeyResult" multiple style="z-index: auto;">
        <v-expansion-panel style="position: relative;">
            <v-expansion-panel-header :disable-icon-rotate="true" :hide-actions="true" class="goal-item-header">
                <v-list class="py-0">
                    <v-list-item class="okr-sub-title" v-if="isMoreSeeting && item.ob_status != 1">
                        <!-- align object -->
                        <span>
                            <v-icon aria-hidden="false" class="Tian_Jia" style="font-size: 12px;">
                                mdi-arrow-collapse-up
                            </v-icon>
                        </span>
                        <a v-if="!parentObjOwnerName" @click="openAddAlignDialog">添加对齐</a>
                        <span>
                            <v-menu content-class="elevation-3" offset-y bottom offset-x right nudge-top="6">
                                <template v-slot:activator="{ on:onClick, attrs }">
                                    <v-menu content-class="elevation-0" open-on-hover offset-x right nudge-top="6">
                                        <template v-slot:activator="{ on:onHover, attrs }">
                                            <span class="tooltip-hover" v-bind="attrs" v-on="{...onHover, ...onClick}">{{parentObjOwnerName}}</span>
                                        </template>
                                        <span content-class="elevation-0" @click="openAddAlignDialog" class="tooltip-hover text-caption" style="display: inline-block; width:100px; height:30px; cursor:pointer;">
                                            <v-icon class="mx-1 tooltip-hover" small>mdi-pencil</v-icon>
                                            修改对齐
                                        </span>
                                    </v-menu>
                                </template>
                                <v-card min-width="400">
                                    <v-card-title>
                                        <v-card class="d-flex justify-space-between" flat width="100%">
                                            <div class="d-flex ml-4">
                                                <div class="pobject-user-avatar mr-2">
                                                    <div class="d-flex align-center justify-center">
                                                        <span class="mt-1">{{
                                  !!this.item.ob_parent_objects?this.item.ob_parent_objects.ob_owners.employeeName.substring(0, 1):''
                                }}</span>
                                                    </div>
                                                </div>
                                                <span style="font-size:16px;"> {{ !!this.item.ob_parent_objects?this.item.ob_parent_objects.ob_owners.employeeName:''}} </span>
                                            </div>
                                            <div style=" font-size:14px; color: #6f7886; cursor: pointer;" @click="removeParent">取消对齐</div>
                                        </v-card>
                                    </v-card-title>
                                    <v-divider class="mx-4"></v-divider>
                                    <v-card-text>
                                        <v-list-item style="cursor:pointer;">
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <router-link class="routing-link" plain text :to="
                                    `/home/okr_goal/okr_e_${!!this.item.ob_parent_objects?this.item.ob_parent_objects.ob_id:''}&pageItemCount=${pageItemCount}&currentPage=${currentPage}`
                                  " color="black">
                                                        <div class="okr-oitem ml-0">
                                                            <div class="okr-oitem-circle"></div>
                                                        </div>
                                                        <span>
                                                            {{!!item.ob_parent_object?getParentObjFromAddSortList(item.ob_parent_object).ob_name:''}}
                                                        </span>
                                                    </router-link>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item v-if="!item.ob_parent_kr || (item.ob_parent_kr && item.ob_parent_kr===kr_item.kr_id)" v-for="(kr_item, i) in !!item.ob_parent_object?getParentObjFromAddSortList(item.ob_parent_object).ob_results:[]" style="cursor:pointer;" @click="selectParentKR(kr_item)">
                                            <v-list-item-content class="pl-5">

                                                <v-list-item-title>
                                                    <span style="color: #2879ff; font-size:13px;">
                                                        {{'KR'+(kr_item.kr_order)}}
                                                    </span>
                                                    <span class="kr-name" style=" font-size:12px;">
                                                        {{kr_item.kr_name}}

                                                    </span>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>

                                    </v-card-text>
                                </v-card>
                            </v-menu>

                        </span>

                    </v-list-item>
                    <v-list-item class="okr-title-container justify-start">
                        <div class="okr-oitem">
                            <div class="okr-oitem-circle"></div>
                        </div>
                        <a class="okr-title">
                            <ToolTipComponent :tooltipText="item.ob_name" direction="top" v-if="!obNameEdit">
                                <router-link class="routing-link" plain text :to="
                        `/home/okr_goal/okr_e_${item.ob_id}&pageItemCount=${pageItemCount}&currentPage=${currentPage}`
                      " color="black">
                                    <span class="text-capitalize text-h6 op_1" @click="selectObject">{{item.ob_name}}</span>
                                    <span class="ml-4 end-goal" v-if="item.ob_status == 1">已结束</span>
                                </router-link>
                            </ToolTipComponent>
                            <span class="" v-if="obNameEdit" v-click-outside="closeObNameEdit">
                                <input type="text" class="ob-name-edit" :value="item.ob_name" @input="changeObName" v-on:keyup.enter="closeObNameEdit" />
                            </span>
                            <span @click="nameEdit" v-if="!obNameEdit && item.ob_status != 1 && item.ob_owner == authUser.id">
                                <v-icon aria-hidden="false" style="font-size: 15px;" outlined>
                                    mdi-tooltip-edit
                                </v-icon>
                            </span>
                        </a>
                        <div class="actions">
                            <v-layout>
                                <div class="obj-score mr-8" v-if="item.ob_score != null">
                                    <ToolTipComponent tooltipText="评分" direction="top">
                                        <span v-if="item.ob_score >= 0.7 && item.ob_score <= 1" style="color:rgb(40, 121, 255);background-color:rgba(40, 121, 255, 0.3);">{{item.ob_score}}分</span>
                                        <span v-if="item.ob_score < 0.7 && item.ob_score >= 0.4" style="color:rgb(246, 189, 22);background-color:rgba(246, 189, 22, 0.3);">{{item.ob_score}}分</span>
                                        <span v-if="item.ob_score < 0.4 && item.ob_score >= 0" style="color:rgb(240, 94, 94);background-color:rgba(240, 94, 94, 0.3);">{{item.ob_score}}分</span>
                                    </ToolTipComponent>
                                </div>
                                <div class="user-name">
                                    <v-menu offset-y v-if="item.ob_status == 0">
                                        <template v-slot:activator="{ on, attrs }">
                                            <span v-bind="attrs" v-on="on">
                                                <v-icon aria-hidden="true" class="mdi_accout" style="font-size:18px; color: #89919f;">
                                                    mdi-account-outline
                                                </v-icon>
                                                <ToolTipComponent :content="item.ob_owners.employeeName" direction="top" :tooltipText="item.ob_owners.employeeName" />
                                            </span>
                                        </template>
                                        <v-list class="py-0">
                                            <v-list-item class="px-0">
                                                <UserPicker @pick="(param) => pickUser(param)" />
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                    <div class="confirm-dialog" v-if="owner_modal">
                                        <div class="text-left" style="color: rgb(31, 35, 41);">
                                            您确定把负责人的权限转交吗?
                                        </div>
                                        <div class="text-right mt-4">
                                            <v-btn class="mr-4" dense @click="closeCycleModal">取消</v-btn>
                                            <v-btn dense color="primary" @click="changeObjOwner">确定</v-btn>
                                        </div>
                                    </div>
                                    <span v-if="item.ob_status == 1">
                                        <v-icon aria-hidden="true" class="mdi_accout" style="font-size:18px; color: #89919f;">
                                            mdi-account-outline
                                        </v-icon>
                                        <ToolTipComponent :content="item.ob_owners.employeeName" direction="top" :tooltipText="item.ob_owners.employeeName" />
                                    </span>
                                </div>
                                <div v-if="item.ob_status == 0">
                                    <!-- color="rgb(159, 191, 255)" -->
                                    <!-- {{item.ob_recent_pr.pr_status}} -->
                                    <!-- <v-progress-linear :value="item.ob_progress" color="rgb(159, 191, 255)"  height="14" :rounded="true" class="okr-progress-linear" @click="openPercentDialog"> -->
                                    
                                    <!-- v-show="item.ob_recent_pr.pr_status" :color="item.ob_recent_pr && item.ob_recent_pr.pr_status === 0? 'blue' : (item.ob_recent_pr.pr_status === 1? 'yellow': 'red')  "-->
                                    <v-progress-linear  :value="item.ob_progress" striped :color="item.ob_recent_pr !== null?  item.ob_recent_pr.pr_status === 0? 'blue' : (item.ob_recent_pr.pr_status === 1? 'yellow': 'red') :'blue' "  height="14" :rounded="true" class="okr-progress-linear" @click="openPercentDialog">
                                        <template v-slot:default="{ value }">
                                            <span>{{ item.ob_progress }}%</span>
                                        </template>
                                    </v-progress-linear>
                                    <div class="progress-description mt-1 d-flex justify-space-between" v-if="item.ob_recent_pr != null">
                                        <span class="left" v-if="item.ob_recent_pr.pr_up_percent != 0">{{pr_up_percent_date}} 更新</span>

                                        <!-- ob_auto_progress 1:根据关键成果KR完成度自动更新, 2: 根据子目标完成度自动更新  自动更新不再显示上升下降值 -->
                                        <span class="right" v-if="item.ob_recent_pr.pr_up_percent > 0 && ![1,2].includes(item.ob_auto_progress) ">  
                                            <v-icon aria-hidden="false" style="font-size:14px; color:rgb(90, 216, 166);">
                                                mdi-arrow-up
                                            </v-icon>
                                            <span :style="item.ob_recent_pr.pr_up_percent > 0 ? 'color:rgb(90, 216, 166)' : ''">{{item.ob_recent_pr.pr_up_percent}}%</span>
                                        </span>
                                        <span class="right" v-if="item.ob_recent_pr.pr_up_percent < 0  && ![1,2].includes(item.ob_auto_progress)">
                                            <v-icon aria-hidden="false" style="font-size:14px; color:rgb(240, 94, 94);">
                                                mdi-arrow-down
                                            </v-icon>                        
                                            <span :style="item.ob_recent_pr.pr_up_percent < 0 ? 'color:rgb(240, 94, 94)' : ''">{{Math.abs(item.ob_recent_pr.pr_up_percent)}}%</span>
                                        </span>
                                    </div>
                                </div>
                                <div v-if="item.ob_status == 1">
                                    <v-progress-linear :value="item.ob_progress" color="rgb(159, 191, 255)" height="14" :rounded="true" class="okr-progress-linear">
                                        <template v-slot:default="{ value }">
                                            <span>{{ item.ob_progress }}%</span>
                                        </template>
                                    </v-progress-linear>
                                    <div class="progress-description mt-1 d-flex justify-space-between" v-if="item.ob_recent_pr != null">
                                        <span class="left">{{pr_up_percent_date}} 更新</span>
                                        <span class="right" v-if="item.ob_recent_pr.pr_up_percent > 0">
                                            <v-icon aria-hidden="false" style="font-size:14px; color:rgb(90, 216, 166);">
                                                mdi-arrow-up
                                            </v-icon>
                                            <span :style="item.ob_recent_pr.pr_up_percent > 0 ? 'color:rgb(90, 216, 166)' : ''">{{item.ob_recent_pr.pr_up_percent}}%</span>
                                        </span>
                                        <span class="right" v-if="item.ob_recent_pr.pr_up_percent < 0">
                                            <v-icon aria-hidden="false" style="font-size:14px; color:rgb(240, 94, 94);">
                                                mdi-arrow-down
                                            </v-icon>
                                            <span :style="item.ob_recent_pr.pr_up_percent < 0 ? 'color:rgb(240, 94, 94)' : ''">{{Math.abs(item.ob_recent_pr.pr_up_percent)}}%</span>
                                        </span>
                                    </div>
                                </div>
                                <a class="ml-5 action" v-if="item.ob_owner == authUser.id">
                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on" aria-hidden="false" class="edit-dot-icon" dense>
                                                mdi-dots-horizontal
                                            </v-icon>
                                        </template>
                                        <v-list dense>
                                            <v-list-item @click.stop="changeObject" v-if="item.ob_status != 1">
                                                <span class="mr-2">
                                                    <v-icon style="font-size: 18px;">mdi-pencil</v-icon>
                                                </span>
                                                编辑基本信息
                                            </v-list-item>
                                            <v-list-item @click.stop="openAddAlignDialog" v-if="item.ob_status != 1">
                                                <span class="mr-2">
                                                    <v-icon style="font-size: 18px;">mdi-arrow-collapse-up</v-icon>
                                                </span>
                                                对齐目标
                                            </v-list-item>
                                            <v-list-item @click.stop="endGoal" v-if="item.ob_status != 1">
                                                <ConfirmDialogComponent option="endGoal" v-on:click="(bool) => endGoal(bool)">
                                                    <span class="mr-2">
                                                        <v-icon style="font-size: 18px;">mdi-power-settings</v-icon>
                                                    </span>
                                                    结束目标
                                                </ConfirmDialogComponent>
                                            </v-list-item>
                                            <v-list-item @click.stop="restartGoal" v-if="item.ob_status == 1">
                                                <ConfirmDialogComponent option="restartGoal" v-on:click="(bool) => restartGoal(bool)">
                                                    <span class="mr-2">
                                                        <v-icon style="font-size: 18px;">mdi-rotate-left</v-icon>
                                                    </span>
                                                    重启目标
                                                </ConfirmDialogComponent>
                                            </v-list-item>
                                            <v-list-item @click.stop="copyObject">
                                                <span class="mr-2">
                                                    <v-icon style="font-size: 18px;">mdi-content-copy</v-icon>
                                                </span>
                                                复制目标
                                            </v-list-item>
                                            <v-list-item @click.stop="stop">
                                                <ConfirmDialogComponent v-on:click="confirmClick" option="obj">
                                                    <span class="mr-2">
                                                        <v-icon style="font-size: 18px;">mdi-delete</v-icon>
                                                    </span>
                                                    删除目标
                                                </ConfirmDialogComponent>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>

                                    <!-- <div class="popup">

                  </div> -->
                                </a>
                                <span v-else style="width:40px;"></span>
                            </v-layout>
                        </div>
                    </v-list-item>
                    <v-list-item class="okr-sub-title not-hover" v-if="isMoreSeeting">
                        <span @click.stop="openCyclePicker" style="cursor:pointer;">
                            <v-icon aria-hidden="false" class="" style="font-size: 12px;">
                                mdi-clock-outline
                            </v-icon>
                            {{itemCycle}}
                        </span>
                        <span class="ml-3" v-if="!item.ob_owners.employeeName">
                            <v-icon aria-hidden="false" class="" style="font-size: 12px;">
                                mdi-clock-outline
                            </v-icon>
                            对齐我的：{{ item.ob_owners.employeeName }}
                        </span>
                    </v-list-item>
                </v-list>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="goal-item-content">
                <hr class="okr-list-item-divide" />
                <v-list class="key-result-item-list py-0">
                    <draggable group="keyresult" v-model="item.ob_results" @change="dragChange">
                        <div v-for="(kr, i) in item.ob_results" :key="kr.kr_id">
                            <KeyResultItemComponent :keyResult="kr" :obId="item.ob_id" :obStatus="item.ob_status" @selectKR="selectKR" :isSimple="true" :stopAllEdit="item.ob_status == 1 || item.ob_owner != authUser.id" />
                        </div>
                    </draggable>
                </v-list>
            </v-expansion-panel-content>
            <div class="goal-item-footer">
                <hr class="okr-list-item-divide" />
                <v-layout justify-space-between v-if="!isKeyResult">
                    <v-flex class="okr-sub-title d-flex align-center justify-start">
                        <a class="d-flex align-center" @click="openQuickKeyResult">
                            <v-icon aria-hidden="false" class="" style="font-size: 18px;">
                                mdi-plus
                            </v-icon>
                            <span>添加KR</span>
                        </a>
                    </v-flex>
                    <v-flex class="d-flex justify-end">
                        <a class="d-flex align-center mr-8" >
                             <router-link class="routing-link" plain text :to="
                                    `/home/okr_goal/okr_e_${item.ob_id}&pageItemCount=${pageItemCount}&currentPage=${currentPage}&tabIndex=2`
                                  " color="black">
                            <span class="icon-text">进展</span>
                            
                            <v-icon aria-hidden="false" class="" style="font-size: 18px;">
                                mdi-file-document
                            </v-icon>
                            </router-link>
                        </a>
                        <a class="d-flex align-center mr-8" @click="onShareReport">
                            <span class="icon-text">分享</span>
                            <v-icon aria-hidden="false" class="" style="font-size: 18px;">
                                mdi-share-variant
                            </v-icon>
                        </a>
                        <div class="d-flex align-center mr-4">
                            <!-- <span class="icon-text">点赞33</span>
                            <v-icon aria-hidden="false" class="" style="font-size: 18px;">
                                mdi-thumb-up-outline
                            </v-icon> -->
                            <LikingComponent rp_type="4" :rp_id="item.ob_id" :curTime="getFullTime.month+'/'+ getFullTime.day +' '+ getFullTime.hour +':'+ getFullTime.minute" />
                        </div>
                        <!-- <span>0</span> -->
                    </v-flex>
                </v-layout>
                <v-layout class="key-result-add" v-if="isKeyResult">
                    <v-flex class="key-result-input">
                        <input  placeholder="创建关键成果KR，回车即可创建成功" type="text" v-on:keyup.enter="(ev) => saveKeyResult(ev, item.ob_id)" />
                    </v-flex>

                    <v-flex class="d-flex align-center right">
                        <RatingPopupComponent :selected="selectedRating" v-on:selectRating="(param) => selectRating(param)">
                            <div class="rating d-flex align-center">
                                <v-icon aria-hidden="false" class="mr-1" style="font-size: 18px; color: #FFCA2A;">
                                    mdi-heart
                                </v-icon>
                                <span class="mr-1">{{ selectedRating }}</span>
                                <span>
                                    分
                                </span>
                            </div>
                        </RatingPopupComponent>
                    </v-flex>
                    <v-flex class="key-result-close">
                        <a @click="closeQuickKeyResult" class="d-flex align-center justify-center">
                            <div class="text-center">
                                <span>
                                    X
                                </span>
                            </div>
                        </a>
                    </v-flex>
                </v-layout>
            </div>
            <div v-if="item.ob_status == 1 || item.ob_owner != authUser.id" class="overlay1"></div>
        </v-expansion-panel>
    </v-expansion-panels>
    <CycleRangePicker @cancel="closeCyclePicker" @ok="changeCycle" style="top:100px;left:130px;" v-if="cyclePicker" v-bind:object="objectToChange" />
    <AddSort ref="addsort" v-on:setPersonalGoal="(param) => setParent(param)" v-on:setUnlink="setUnlink" />
    <!-- <PercentDialogComponent
      ref="percent"
      :isAuto="item.ob_auto_progress"
      :percent="item.ob_progress"
      :autoPercent="autoPercent"
      v-on:savePercentValue="(param) => getPercentValue(param)"
      v-on:saveProgressOption="(param) => saveProgressOption(param)"
    /> -->
    <!-- pr_parent_type   0:object,1:kr,2:task,3:item -->
    <NewPercentDialogComponent ref="percent" v-show="item.ob_recent_pr !==null? item.ob_recent_pr.pr_status:'item'" :parent_id="item.ob_id" :parent_type="0" :status_type="item.ob_recent_pr !== null? item.ob_recent_pr.pr_status : '' " :progress_desc="item.ob_recent_pr?.pr_description" :percent="item.ob_progress" :autoOption="item.ob_auto_progress" @savePercentValue="(param) => getPercentValue(param)" :firstOptionPercentValue="firstValue" :secondOptionPercentValue="secondValue" />
    <CopyWizard v-bind:object="objectToClone" @clone="cloneObject" ref="copyWizard" />
    <ObjectFormDlg @ok="updateObject" v-bind:object="objectToChange" ref="objectFormDlg" />
     <ShareReportDlg ref="ShareReportDlg" :ic_obj="{ic_parent:1,ic_parent_type:19}"/>
</v-card>
</template>

<script>
import ToolTipComponent from "@/components/okrgoal/shared/ToolTipComponent.vue";
import KeyResultItemComponent from "@/components/okrgoal/shared/KeyResultItemComponent.vue";
import AddSort from "@/components/common/AddSort.vue";
import PercentDialogComponent from "@/components/okrgoal/shared/PercentDialogComponent.vue";
import NewPercentDialogComponent from "@/components/okrgoal/shared/NewPercentDialogComponent.vue";
import RatingPopupComponent from "@/components/okrgoal/shared/RatingPopupComponent.vue";
import ConfirmDialogComponent from "@/components/okrgoal/shared/ConfirmDialogComponent.vue";
import UserPicker from "@/components/common/UserPicker.vue";
import {
    constant
} from "@/constants/constant.js";
import draggable from "vuedraggable";
import {
    mapGetters,
    mapActions
} from "vuex";
import {
    getUser
} from '@/utils/authUser.service';
import {
  Routes
} from "@/constants/routes.js";

export default {
    props: ["item", "okrObj", "pageItemCount", "currentPage"],
    components: {
        ToolTipComponent,
        KeyResultItemComponent,
        AddSort,
        PercentDialogComponent,
        NewPercentDialogComponent,
        RatingPopupComponent,
        Notification,
        ConfirmDialogComponent,
        UserPicker,
        draggable,
        CopyWizard: () => import('@/components/common/CopyWizard.vue'),
        ObjectFormDlg: () => import('@/components/common/ObjectFormDlg.vue'),
        CycleRangePicker: () => import('@/components/common/CycleRangePicker.vue'),
        ShareReportDlg: () => import("@/components/okrgoal/okrDetail/E-weekly/ShareReportDlg.vue"),
        LikingComponent:() => import('@/components/okrgoal/LikingOnlyComponent.vue'),
    },
    data() {
        return {
            okr_edit: false,
            percent: 0,
            isKeyResult: false,
            selectedRating: 5,
            obNameEdit: false,
            obNameChangeValue: "",
            isDisableKeyResult: false,
            krArr: this.item.ob_results,
            owner_modal: false,
            owner_obj: null,
            objectToClone: null,
            objectToChange: null,
            cyclePicker: false
        };
    },
    mounted: function () {

    },
    computed: {
        // watcher: function() {
        //   return getUser().id;
        // },
        ...mapGetters('auth', ['authUser']),
        ...mapGetters('okr', ['OkrObjForAddsort']),
        pr_up_percent_date: function () {
            const date = new Date(this.item.ob_recent_pr.created_at);
            let month = date.getMonth() + 1;
            let day = date.getDate();
            month = month < 10 ? '0' + month : month;
            return month + '/' + day;
        },
        parentObjOwnerName: function () {
            if (!!this.item.ob_parent_objects) {
                return this.item.ob_parent_objects.ob_name
            } else {
                return null;
            }
        },
        itemCycle: function () {
            let cycles = this.item.ob_cycle.split("/");
            if (cycles.length == 1) {
                cycles = this.item.ob_cycle.split("-");
                let result = constant.cycleItems.filter(c => c.dbformat == ('-' + cycles[1]));
                return result[0].title;
            } else {
                let result = constant.cycleItems.filter(c => c.dbformat == ('/' + cycles[1]));
                return result[0].title;
            }
        },
        okrObjList: function () {
            let okrObjList = [];
            this.okrObj.map((obj) => {
                let obc = obj.ob_cycle;
                let year = obj.ob_start_date + "-" + obj.ob_end_date;
                let cycle = "";
                let goal = "全部状态";
                let quater = "";
                if (obc.includes("/")) {
                    quater = "/" + obc.split("/")[1];
                }
                if (obc.includes("-")) {
                    quater = "-" + obc.split("-")[1];
                }
                constant.cycleItems.map((c) => {
                    if (c.dbformat == quater) {
                        cycle = c.title;
                    }
                });
                constant.statusItems.map((s) => {
                    if (s.dbformat == obj.ob_status) {
                        goal = s.title;
                    }
                });
                let tmp = {
                    id: obj.ob_id,
                    cycle: cycle,
                    content: obj.ob_name,
                    year: year,
                    goal: goal,
                    parent: this.item.ob_parent_object === obj.ob_id ? true : false,
                };
                if (obj.ob_id !== this.item.ob_id) {
                    okrObjList.push(tmp);
                }
            });
            return okrObjList;
        },
        isExpansionToggle: {
            get() {
                if (this.item.ob_results != 0) {
                    return this.$store.getters["app/isExpansionToggle"];
                } else {
                    return [1];
                }
            },
            set(newValue) {
                // return this.setExpansionToggle();
            },
        },
        isMoreSeeting: {
            get() {
                return this.$store.getters["app/isMoreSeeting"];
            },
            set(newValue) {},
        },
        autoPercent: function () {
            let value = 0;
            let len = this.item.ob_results.length;
            this.item.ob_results.map((result) => {
                value += result.kr_completion;
            });
            return Math.floor(value / len);
        },
        firstValue: function () {
            let value = 0;
            let len;
            if (!!this.item.ob_results) {
                len = this.item.ob_results.length;
                this.item.ob_results.map((result) => {
                    value += result.kr_completion;
                });
            }
            if (value != 0 && len != 0) {
                return Math.floor(value / len).toFixed(0);
            } else {
                return 0;
            }
        },
        secondValue: function () {
            let value = 0;
            let len;
            if (!!this.item.ob_child_objects) {
                len = this.item.ob_child_objects.length;
                this.item.ob_child_objects.map((result) => {
                    value += result.ob_progress;
                });
            }
            if (value != 0 && len != 0) {
                return Math.floor(value / len).toFixed(0);
            } else {
                return 0;
            }
        },
        getFullTime() {
            if (!!this.item) {
                let date = new Date(this.item.ob_recent_pr);
                let year = date.getFullYear();
                let month = date.getMonth() + 1;
                if (month < 10) {
                month = "0" + month;
                }
                let day = date.getDate();
                let hour = date.getHours();
                if (hour < 10) {
                hour = "0" + hour;
                }
                let minute = date.getMinutes();
                if (minute < 10) {
                minute = "0" + minute;
                }
                return {
                year: year,
                month: month,
                day: day,
                hour: hour,
                minute: minute,
                }
            }
        },
    },
    methods: {
        ...mapActions("okr", [
            "updateOkr",
            "createKeyResult",
            "deleteOkr",
            "updateKeyResult",
            "getOkrs",
            "updateOkrAndGet",
            "getOkrsForAddsort",
            "addOkr"
        ]),
        ...mapActions("progress", ["addProgress"]),
        ...mapActions("app", ["setExpansionToggle"]),
        getParentObjFromAddSortList(obId) {
            const index = this.OkrObjForAddsort.findIndex(item => item.ob_id == obId)
            if (index != -1) {
                return this.OkrObjForAddsort[index];
            } else {
                return {
                    ob_name: '',
                    ob_results: []
                };
            }
        },
        stop(ev) {
            ev.stopPropagation();
        },
        changeObjOwner(ev) {
            ev.stopPropagation();
            this.owner_modal = false;
            let obj = {
                ob_id: this.item.ob_id,
                ob_owner: this.owner_obj.user.id,
            };
            this.updateOkr(obj);
            this.item.ob_owners.employeeName = this.owner_obj.user.employeeName;
        },
        closeCycleModal(ev) {
            ev.stopPropagation();
            this.owner_modal = false;
        },
        restartGoal(bool) {
            let obj = {
                ob_id: this.item.ob_id,
                ob_status: 0,
            };
            if (this.item.ob_status == 1 && bool) {
                this.updateOkr(obj);
            }
            // ev.stopPropagation()
        },
        endGoal(bool) {
            let obj = {
                ob_id: this.item.ob_id,
                ob_status: 1,
            };
            if (this.item.ob_status !== 1 && bool) {
                this.updateOkr(obj);
            }
            // ev.stopPropagation()
        },
        openAddAlignDialog(ev) {
            ev.stopPropagation();
            this.$refs.addsort.openDialog();
        },
        openPercentDialog(ev) {
            ev.stopPropagation();
            this.$refs.percent.openDialog();
        },
        removeParent(obId) {
            this.updateOkrAndGet({
                ob_id: this.item.ob_id,
                ob_parent_object: 0,
                ob_parent_kr: 0
            })
        },
        selectParentKR(data) {
            let obj = {
                krObj: data,
                obId: this.item.ob_id,
                obStatus: this.item.ob_status
            }
            this.$emit("selectKR", obj);
        },
        selectKR(data) {
            this.$emit("selectKR", data);
        },
        nameEdit(ev) {
            ev.stopPropagation();
            this.obNameEdit = true;
        },
        setParent(param) {
            let obj = {
                ob_id: this.item.ob_id,
                ob_parent_object: param.ob_id,
                ob_parent_kr: param.ob_parent_kr_obj ? param.ob_parent_kr_obj.kr_id : 0,
            };
            this.updateOkrAndGet(obj);
        },   
        getPercentValue(param) {
           // debugger;
            console.log(param,'param----')
            console.log(this.item,'this.item')
            const pr_change = param.pr_percent - this.item.ob_progress;

            if (!!this.item.ob_recent_pr) {
                this.item.ob_recent_pr.pr_up_percent = pr_change;
                this.item.ob_recent_pr.created_at = Date();
            } else {
                this.item.ob_recent_pr = {};
                this.item.ob_recent_pr.pr_up_percent = pr_change;
                this.item.ob_recent_pr.created_at = Date();
            }
            this.item.ob_progress = param.pr_percent;
            this.item.ob_auto_progress = param.option;
            this.item.ob_recent_pr.pr_status = param.pr_status

            param.pr_parent = this.item.ob_id;
            let obj = {
                ob_id: this.item.ob_id,
                ob_progress: param.pr_percent,
                ob_auto_progress: param.option,
                //new
                // ob_recent_pr: this.item.ob_recent_pr
            };
            this.updateOkr(obj);
            this.addProgress(param);
           // this.getOkrs({})
           // this.scrollToTop()
            // if (!!this.item.ob_parent_object && this.item.ob_parent_object != 0) {
            //     this.getOkrs({});
            // }
        },
        scrollToTop() { 
            setTimeout(() => {
        　　    document.documentElement.scrollTop = 0
            }, 500);
        },
        openQuickKeyResult() {
            this.isKeyResult = true;
        },
        closeQuickKeyResult() {
            this.isKeyResult = false;
            this.selectedRating = 5;
        },
        selectRating(param) {
            this.selectedRating = param;
        },
        saveKeyResult(ev, ob_id) {
            ev.stopPropagation();
            let content = ev.target.value.replace(/ /g, '')
            let order = this.item.ob_results.length;
            let kr_obj = {
                kr_name: content,
                kr_owner: getUser().id,
                kr_parent_object: ob_id,
                kr_order: order + 1,
                kr_completion: 0,
                kr_confidence: this.selectedRating,
                kr_score: 0,
                kr_score_description: null,
                kr_ta: null,
                kr_start_date: this.item.ob_start_date,
                kr_end_date: this.item.ob_end_date,
            };
            if (content != '') {
                this.createKeyResult(kr_obj);
            }
            this.isKeyResult = false;
        },
        closeObNameEdit(ev) {
            ev.stopPropagation();
            let obj = {
                ob_id: this.item.ob_id,
                ob_name: this.obNameChangeValue,
            };
            if (this.obNameChangeValue !== "") {
                this.updateOkr(obj);
            }
            this.obNameEdit = false;
        },
        changeObName(ev) {
            ev.stopPropagation();
            this.obNameChangeValue = ev.target.value;
        },
        confirmClick(param) {
            let obj = {
                ob_id: this.item.ob_id,
                ob_status: param
            }
            this.deleteOkr(obj);
        },
        saveProgressOption(param) {
            let obj = {
                ob_id: this.item.ob_id,
                ob_auto_progress: param,
            };
            this.updateOkr(obj);
        },
        setUnlink() {
            let obj = {
                ob_id: this.item.ob_id,
                ob_parent_object: 0,
            };
            this.updateOkr(obj);
        },
        pickUser(param) {
            this.owner_modal = true;
            this.owner_obj = param;
        },
        dragChange(ev) {
            this.item.ob_results.map((kr, i) => {
                kr.kr_order = i + 1;
                let obj = {
                    kr_id: kr.kr_id,
                    kr_order: i + 1,
                };
                let payload = {
                    obj: obj,
                    kr: kr,
                };
                if (ev.moved.oldIndex > ev.moved.newIndex) {
                    if (i >= ev.moved.newIndex && i <= ev.moved.oldIndex) {
                        this.updateKeyResult(payload);
                    }
                } else {
                    if (i <= ev.moved.newIndex && i >= ev.moved.oldIndex) {
                        this.updateKeyResult(payload);
                    }
                }
            });
        },
        selectObject() {
            this.$emit("selectObject");
        },
        copyObject() {
            this.objectToClone = Object.assign({}, this.item);
            this.$refs.copyWizard.open();
        },
        cloneObject() {
            this.addOkr(this.objectToClone);
        },
        changeObject() {
            this.objectToChange = Object.assign({}, this.item);
            this.$refs.objectFormDlg.open();
        },
        updateObject() {
            this.updateOkr(this.objectToChange);
        },
        openCyclePicker() {
            // e.stopPropagation();
            if (this.item.ob_owner === this.authUser.id && this.item.ob_status == 0) {
                this.objectToChange = Object.assign({}, this.item);
                this.cyclePicker = true;
                // this.$emit('cycle')
            }
        },
        changeCycle() {
            this.updateOkr(this.objectToChange);
            this.cyclePicker = false;
        },
        closeCyclePicker(e) {
            this.cyclePicker = false;
        },
        //分享
        onShareReport() {
            this.$refs.ShareReportDlg.open({
                ob_name: this.item.ob_name,
                e_start: this.item.ob_start_date,
                e_end:  this.item.ob_end_date ,
                path: '/home/okr_goal/okr_e_'+this.item.ob_id +'&pageItemCount='+this.pageItemCount + '&currentPage='+this.currentPage
            });
        },
    },
};
</script>

<style>
.pobject-header {
    height: 36px;
    padding: 12px 24px;
    border-bottom: 1px solid #e9ecf0;
}

.pobject-user-avatar div {
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 56px;
    background-color: rgb(185, 204, 79);
    color: rgb(255, 255, 255);
    font-size: 14px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
}

.goal-item {
    box-shadow: none !important;
    background: #c00;
}

.routing-link {
    text-decoration: none;
}

.goal-item-header {
    padding: 12px 20px 12px 48px !important;
    position: relative;
    z-index: 1;
}

.goal-item-header .ob-name-edit {
    width: 98%;
}

.not-hover a {
    color: #6f7886 !important;
    cursor: auto;
}

.okr-sub-title {
    font-size: 12px;
    min-height: 24px;
    padding: 0px;
}

.okr-sub-title a {
    color: #6f7886;
}

.okr-sub-title:hover a {
    color: #2879ff;
}

.okr-sub-title:hover a .Tian_Jia {
    color: #2879ff;
}

.okr-title-container {
    padding: 0px;
    min-height: 30px;
}

.okr-title {
    font-size: 16px;
    font-weight: bold;
    width: 100%;
}

.okr-title {
    color: rgb(20, 28, 40) !important;
}

.okr-title i {
    display: none !important;
}

.okr-title:hover i {
    display: inline-block !important;
}

.okr-title:hover {
    color: #2879ff !important;
}

.okr-sub-title span {
    color: #6f7886;
}

.actions {
    margin-left: auto;
}

.actions .action {
    position: relative;
}

.actions .action:hover .popup {
    display: block;
    z-index: 30;
}

.actions .action .popup:hover {
    display: block;
}

.actions .action .popup {
    display: none;
    position: absolute;
    top: 20px;
    right: 0;
    width: 158px;
    padding: 0px;
    padding-top: 5px;
    border: 1px solid rgb(240, 242, 245);
    background-color: #fff;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
        0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.actions .action .popup .v-list-item {
    min-height: 24px;
    margin-bottom: 0px;
    padding: 5px 24px;
    font-size: 14px;
    line-height: 22px;
    transition: all 0.3s ease-in 0s;
    color: rgb(63, 71, 85);
    cursor: pointer;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    white-space: nowrap;
}

.actions .action .popup .v-list-item:hover {
    color: rgb(40, 121, 255) !important;
    background: rgba(240, 242, 245, 0.3);
}

.user-name {
    display: inline-block;
    color: #89919f;
    width: 75px;
    /* overflow: hidden;
  text-overflow: ellipsis; */
    word-break: break-all;
    white-space: nowrap;
    vertical-align: middle;
    font-size: 13px;
    line-height: 18px;
    margin-right: 40px;
    position: relative;
}

.confirm-dialog {
    width: 300px;
    height: 100px;
    position: absolute;
    top: 20px;
    right: -50px;
    border: 1px solid #fff;
    box-shadow: 1px 1px 10px #eee;
    padding: 16px 20px;
    border-radius: 4px;
    box-shadow: rgb(31 35 41 / 11%) 0px 0px 28px 0px;
    background: rgb(255, 255, 255);
}

.okr-progress-linear {
    width: 160px;
    font-size: 12px;
    z-index: 10;
}

.progress-description {
    font-size: 12px;
    color: rgb(137, 145, 159);
}

.progress-description .left {}

.progress-description .right {
    /* color: rgb(90, 216, 166); */
}

.edit-dot-icon {
    font-weight: bold;
    padding: 2px;
}

.edit-dot-icon:hover {
    background: rgb(164, 172, 185);
    border-radius: 50%;
    cursor: pointer;
}

.okr-oitem {
    height: 24px;
    width: 24px;
    padding: 6px;
    box-sizing: border-box;
    background: rgba(40, 121, 255, 0.1);
    border-radius: 12px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    margin-left: -32px;
    cursor: pointer;
}

.okr-oitem-circle {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 1px solid #2879ff;
}

.goal-item-content {
    padding: 0;
    padding-bottom: 12px;
}

.goal-item-content .v-expansion-panel-content__wrap {
    padding: 0px 20px 0px 48px;
}

.okr-list-item-divide {
    margin-bottom: 16px;
}

.key-result-item-list {
    font-size: 14px;
    min-height: 36px;
}

.goal-item-footer {
    padding: 0px 20px 12px 48px;
}

.key-result-add {}

.key-result-add .right {
    margin-left: -72px;
    z-index: 20;
}

.key-result-input {
    width: 95%;
}

.key-result-input input {
    min-height: 36px;
    width: 100%;
}

.goal-content input {
    box-sizing: border-box;
    position: relative;
    background: rgb(255, 255, 255);
    cursor: text;
    border: 1px solid rgb(233, 236, 240);
    border-radius: 4px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 7px 7px;
    transition: border 0.2s ease 0.2s, box-shadow 0.2s ease 0.2s;
    color: #3f4755 !important;
}

.goal-content input:focus {
    outline: none;
    border: 1px solid rgb(40, 121, 255);
    box-shadow: rgb(40 121 255 / 30%) 0px 0px 6px 0px inset;
}

.key-result-close {
    width: 50px;
}

.key-result-close a {
    height: 100%;
}

.key-result-close a div {
    height: 26px;
    width: 26px;
    font-size: 12px;
    background-color: rgb(141, 200, 234);
    color: rgb(255, 255, 255);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ky-result-close a div span {
    text-align: center;
}

.op_1 {
    opacity: 1 !important;
    color: black;
}

.obj-score span {
    border-radius: 5px;
    padding: 2px 4px;
    font-size: 12px;
    display: inline-block;
    width: 40px;
}

.end-goal {
    height: 18px;
    margin-left: 6px;
    padding: 2px 8px;
    font-size: 12px;
    line-height: 14px;
    border-radius: 9px;
    background: #f7f8fa;
    box-sizing: border-box;
    color: #a4acb9;
    display: inline-block;
    white-space: nowrap;
}

.overlay1 {
    width: 320px;
    height: 100%;
    background: transparent;
    /* background: #c00; */
    position: absolute;
    top: 0;
    right: 50px;
    z-index: 2;
}

.tooltip-hover:hover {
    color: #1E90FF;
}
.icon-text{
    color:#808080;
    margin-right:5px;
}
</style>
