<template>
<v-container fluid>
  <v-container v-if="!!pageMode"  class="goalstatistic_content">
    <GoalStatistic ref="goalStatistic" />
  </v-container>
  <v-container fluid class="px-0 py-0" style="height: 100%;" v-if="!pageMode">
    <v-skeleton-loader v-if="loading" type="article,list-item-avatar-three-line,list-item-avatar-three-line,list-item-avatar-three-line,list-item-avatar-three-line,list-item-avatar-three-line,list-item-avatar-three-line,list-item-avatar-three-line,list-item-avatar-three-line,list-item-avatar-three-line"></v-skeleton-loader>
    <Header @filter="e => filter=e" @cycle="changeCycleText" @mode="setArrangeMode" :showHint="showHint" @displayRightUserEdit="handle_displayRightUserEdit" />
    <v-sheet class="goal-content">
      <v-container fluid>
        <v-row v-if="!currentObject">
          <!-- <v-sheet v-if="okrObj.length == 0" style="display:flex;align-center;justify-content:center;width:100%;background-color:rgb(247 249 251);padding-top:10px">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-sheet> -->

          <v-col cols="12" v-if="(okrObj.length) !== 0">
            <span v-for="(obj) in okrObj" :key="obj.id" class="pa-2">
              <GoalListItem @cycle="openCyclePicker(obj)" :item="obj" :okrObj="okrObj" :pageItemCount="$route.params.countsPerPage" :currentPage="currentPage" @selectObject="selectObject()" @selectKR="selectKR" />
              <!-- <CycleRangePicker style="position:" @cancel="cyclePicker=null" @ok="changeCycle" v-if="cyclePicker==obj.ob_id" v-bind:object="objectToChange"/> -->
            </span>

          </v-col>

          <v-col v-if="isHidden">
            <GoalAddComponent />
          </v-col>
          <v-col cols="12" class="text-center" v-if="!loading && okrObj.length == 0 && okrFilter.ob_owner == authUser.id && filter == 0">
            <div v-if="!isHidden" style="margin-top: 100px">
              <div class=" text-subtitle-2">
                <div class="my-1">尚未制定 {{ cycleText }} 的 OKRs</div>
                <div class="mt-1 mb-5">如果你不知道如何制定 OKRs，请先了解 
                  <router-link style="text-decoration: none;" target="_blank" to="//wiki.niweixing.com/" class="mr-4 help-hint">
                    <span class="blue--text">什么是 OKRs?</span>
                  </router-link>
                  </div>
                <v-btn class="mb-3" color="#4169E1" dark width="150" @click="isHidden=true">
                  创建 OKR
                </v-btn>
              </div>
              <router-link style="text-decoration:none;" to="/home/library">从 OKR 案例库导入</router-link>
              <div class="text-caption mt-4 aling-center">
                <span class="grey--text">找不到OKR？试试</span>
                <span @click="hintFilters" style="cursor: pointer" class="yellow--text align-center mx-2">
                  <v-icon class="mx-1" color="yellow">mdi-hand-pointing-right</v-icon>检查
                </span>
                <span class="grey--text">顶部筛选条件</span>
              </div>
            </div>
          </v-col>
          <v-col cols="12" class="text-center" v-if="!loading && okrObj.length == 0 && !!okrFilter.ob_participant && !okrFilter.ob_owner">
            <div v-if="!isHidden" style="margin-top: 100px">
              <div class=" text-subtitle-2">
                <div class="mt-1 mb-5">暂无参与的OKR <span class="blue--text">如何参与？</span></div>
              </div>
              <div class="text-caption mt-4 aling-center">
                <span class="grey--text">找不到OKR？试试</span>
                <span @click="hintFilters" style="cursor: pointer" class="yellow--text align-center mx-2">
                  <v-icon class="mx-1" color="yellow">mdi-hand-pointing-right</v-icon>检查
                </span>
                <span class="grey--text">顶部筛选条件</span>
              </div>
            </div>
          </v-col>
          <v-col cols="12" class="text-center" v-if="!loading && okrObj.length == 0 && !!okrFilter.ob_attention && !okrFilter.ob_owner">
            <div v-if="!isHidden" style="margin-top: 100px">
              <div class=" text-subtitle-2">
                <div class="mt-1 mb-5">暂无关注的OKR <span class="blue--text">如何关注？</span></div>
              </div>
              <div class="text-caption mt-4 aling-center">
                <span class="grey--text">找不到OKR？试试</span>
                <span @click="hintFilters" style="cursor: pointer" class="yellow--text align-center mx-2">
                  <v-icon class="mx-1" color="yellow">mdi-hand-pointing-right</v-icon>检查
                </span>
                <span class="grey--text">顶部筛选条件</span>
              </div>
            </div>
          </v-col>
          <v-col v-if="!loading && okrObj.length == 0 && okrFilter.ob_owner != authUser.id && filter == 0" cols="12" class="text-center">
            <div v-if="!isHidden" style="margin-top: 100px">
              <div class=" text-subtitle-2">
                <div class="my-1">尚未制定 {{ cycleText }} 的 OKRs</div>
                <div class="mt-1 mb-5">好的管理，从制定工作目标开始。围绕目标开展重点工作，目标达成更可控。</div>
                <v-btn class="mb-3" color="#4169E1" dark width="150" @click="confirmTaRemind">
                  提醒 ta 创建
                </v-btn>
              </div>
              <div class="text-caption mt-4 aling-center">
                <span class="grey--text">找不到OKR？试试</span>
                <span @click="hintFilters" style="cursor: pointer" class="yellow--text align-center mx-2">
                  <v-icon class="mx-1" color="yellow">mdi-hand-pointing-right</v-icon>检查
                </span>
                <span class="grey--text">顶部筛选条件</span>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="okrObj.length > 0">
          <div class="text-caption mt-4 aling-center ml-auto mr-auto">
            <span class="grey--text">找不到OKR？试试</span>
            <span @click="hintFilters" style="cursor: pointer" class="yellow--text align-center mx-2">
              <v-icon class="mx-1" color="yellow">mdi-hand-pointing-right</v-icon>检查
            </span>
            <span class="grey--text">顶部筛选条件</span>
          </div>
        </v-row>
        <v-row>
          <v-col class="d-flex mr-10" v-if="okrObj.length !== 0">
            <v-spacer></v-spacer>
            <v-pagination :length="
                pageInfo.page_totals
                  ? Math.ceil(
                      pageInfo.page_totals / $route.params.countsPerPage
                    )
                  : 0
              " v-model="currentPage" @input="getPageItems" prev-icon="mdi-menu-left" next-icon="mdi-menu-right">
            </v-pagination>
          </v-col>
        </v-row>
        <v-row v-if="currentObject">
          <OkrDetailComponent />
          <OKRTab />
        </v-row>
      </v-container>
    </v-sheet>
    <OKRUserRightEditor ref="userEditor" />
    <ConfirmDlg ref="confirmDlg" />
  </v-container>
</v-container>
</template>                                                                         

<script>
import {
  mapGetters,
  mapActions,
  mapState
} from "vuex";
import AddSort from "@/components/common/AddSort.vue";
import GoalListItem from "@/components/okrgoal/GoalItemComponent.vue";
import GoalAddComponent from "@/components/okrgoal/GoalAddComponent.vue";
import OKRTab from "@/components/okrgoal/tabView/OKRGoaltab.vue";
import OkrDetailComponent from "@/components/okrgoal/okrDetail/OkrDetailComponent.vue";
import OKRUserRightEditor from "@/components/okrgoal/OKRUserRightEditor.vue";
import {
  Routes
} from "@/constants/routes";
import {
  getUser
} from "@/utils/authUser.service";
import {
  messages
} from "@/constants/messages";
export default {
  props: ['pageMode'],
  components: {
    AddSort,
    GoalListItem,
    GoalAddComponent,
    OKRTab,
    OkrDetailComponent,
    Header: () => import("@/components/okrgoal/Header.vue"),
    OKRUserRightEditor,
    ConfirmDlg: () => import('@/components/common/ConfirmDlg.vue'),
    CycleRangePicker: () => import('@/components/common/CycleRangePicker.vue'),
    GoalStatistic: () => import("@/components/okrgoal/GoalStatistic.vue"),
  },
  data() {
    return {
      currentObject: null,
      isShow: false,
      isHidden: false,
      objs: [],
      updatedOKR: [],
      currentPage: null,
      showHint: false,
      arrangeMode: 1,
      arrangeFilter: "created_at ASC",
      cyclePicker: false,
      objectToChange: null,
      cycleText: '',
      filter: 0,
    };
  },
  computed: {
    ...mapGetters("okr", ["okrObj", "pageInfo", "loading"]),
    ...mapGetters("auth", ["authUser"]),
    ...mapGetters("app", ["okrFilter"]),
    // pageItemCount: this.$route.params.countsPerPage,
    // currentPage: this.$route.params.pageNum
  },
  mounted() {
    this.currentPage = Number(this.$route.params.pageNum);
    // this.getOkrs({
    //   payload: {
    //     page_start: (this.currentPage - 1) * this.$route.params.countsPerPage,
    //     page_counts: this.$route.params.countsPerPage,
    //   },
    // });
    // show SubMenu on OKR Goal
    if (this.currentPage == 1) {
      this.$emit("ShowSubMenu", true);
    }
    // get object list for addsort view
    this.getOkrsForAddsort({
      ob_company_id: this.authUser.user_company_id,
      ob_status: 0,
      ob_owner: this.authUser.id,
    });
  },
  watch: {
    pageMode(v) {
      if (v && this.$refs.goalStatistic) {
        this.$refs.goalStatistic.updateData();
      }
    },
  },
  methods: {
    ...mapActions("okr", ["getOkrs", "getFilteredOkrs", "getOkrsForAddsort"]),
    ...mapActions("app", ["setFilter", "formatFilter"]),
    selectObject() {
      // this.currentObject = this.okrObj[index];
      this.$emit("selectedObject");
    },
    selectKR(data) {
      this.$emit("selectKR", data);
    },
    async confirmTaRemind() {
      if (await this.$refs.confirmDlg.open('确定提醒 Ta 么？', '系统将会发送通知提醒 Ta 去创建 OKR', {})) {
        this.$store.dispatch("app/setNotification", {
          status: "success",
          text: messages.NOTIFICATION_SUCESSFULLY,
        });
      }
    },
    getPageItems() {
      this.getOkrs({
        payload: {
          page_start: (this.currentPage - 1) * this.$route.params.countsPerPage,
          page_counts: this.$route.params.countsPerPage,
        },
      });
      this.$router.push({
        path: `${Routes.OKR_LIST_PAGE}/${this.$route.params.countsPerPage}/${this.currentPage}`,
      });
    },
    hintFilters() {
      this.showHint = true;
      setTimeout(() => {
        this.showHint = false;
      }, 5000);
    },
    handle_displayRightUserEdit(prop) {
      this.$refs.userEditor.open(prop);
    },
    setArrangeMode(e) {
      this.arrangeMode = e;
      let temp = null;
      switch (this.arrangeMode) {
        case 1:
          temp = "created_at ASC";
          break;
        case 2:
          temp = "created_at DESC";
          break;
        case 3:
          temp = "updated_at ASC";
          break;
        default:
          temp = "updated_at DESC";
      }
      this.setFilter({
        ob_sortby: temp,
      });
      this.getOkrs({
        payload: {
          page_start: (this.currentPage - 1) * this.$route.params.countsPerPage,
          page_counts: this.$route.params.countsPerPage,
        },
      });
    },
    openCyclePicker(object) {
      this.objectToChange = Object.assign({}, object);
      this.cyclePicker = object.ob_id;
    },
    changeCycleText(e) {
      if (e.cycle.includes('/')) {
        let temp = e.cycle.split('/');
        const year = temp[0];
        const cycle = temp[1];
        if (cycle == '1000') {
          temp = '全部周期';
        } else if (cycle == '0') {
          temp = '年度';
        } else {
          temp = `第${digits[Number(cycle)]}季度`
        }
        this.cycleText = `「${year}年 ${temp}」`;
      } else if (e.cycle.includes('-')) {
        let temp = e.cycle.split('-');
        const year = temp[0];
        const cycle = temp[1];
        this.cycleText = `「${year}年 第${digits[Number(cycle)]}月」`
      } else {
        this.cycleText = '';
      }
    }
  },
};
const digits = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九','十', '十一', '十二'];
</script>

<style>
.goalstatistic_content {
  max-width : 1025px
}
.goal-content {
  width: 100%;
  height: calc(100% - 140px);
  background-color: #f7f9fb !important;
}

.create-goal-letter {
  font-size: 14px;
}
</style>
