<template>
<div class="text-center">
  <v-dialog v-model="dialog" width="600" persistent>
    <template v-slot:activator="{ on, attrs }">
      <span color="red lighten-2" dark v-bind="attrs" v-on="on">
      </span>
    </template>
    <v-card class="picker-content">
      <v-layout class="d-flex justify-space-between">
        <v-flex class="px-4 py-4">
          <v-text-field placeholder="搜索" outlined autofocus :min-height="36" id="search" dense append-icon="mdi-magnify" v-model="search" @click="stopParentEvent"></v-text-field>
          <div class="searched">
            <v-tabs v-model="tab" icons-and-text height="36">
              <v-tabs-slider width="124"></v-tabs-slider>
              <v-tab href="#tab-1">
                部门
              </v-tab>
              <v-tab href="#tab-2">
                群组
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab" class="">
              <v-tab-item style="height:500px;" id="tab-1">
                <v-treeview :items="departAndUser" v-if="search == ''">
                  <template v-slot:prepend="{ item, open }">
                    <v-btn v-if="item.employeeName" @click="selectUser(item)" plain small>
                      <v-avatar class="mr-2" color="orange" size="30">{{ item.employeeName?.charAt(0) }}</v-avatar>{{ item.employeeName }}
                    </v-btn>
                    <v-btn v-else @click="selectDepartment(item)" plain small>{{ item.departmentOaName }}</v-btn>
                  </template>
                </v-treeview>
                <div class="py-4" v-if="search != ''">
                  <div class="py-1 cursor-pointer" v-for="depart in filteredDepartList" :key="depart.id">
                    <div @click="selectDepartment(depart)">
                      <v-icon style="font-size: 18px;">mdi-menu-right</v-icon>{{depart.departmentOaName}}
                    </div>
                  </div>
                  <div class="py-1 cursor-pointer" v-for="user in filteredUserList" :key="user.id">
                    <div @click="selectUser(user)">
                      <v-avatar class="mr-2" color="orange" size="30">{{ user.employeeName?.charAt(0) }}</v-avatar>{{user.employeeName}}
                    </div>
                  </div>
                </div>
              </v-tab-item>
              <v-tab-item id="tab-2">
              </v-tab-item>
            </v-tabs-items>
          </div>
        </v-flex>
        <v-divider vertical></v-divider>
        <v-flex class="px-4 py-4">
          <div class="label d-flex justify-start align-center">已选： {{selectedUserCount == 0 ? '' : '人员' + selectedUserCount}} {{selectedDepartCount == 0 ? '' : '部门' + selectedDepartCount}}</div>
          <div class="selected mt-4">
            <div class="mt-2" v-for="(user, i) in selectedUser" :key="user.id" style="position:relative;">
              <v-avatar size="30" class="mx-2" color="#E65100">{{ user.employeeName?.charAt(0).toUpperCase() }}</v-avatar>
              <span class="text-capitalize">{{ user.employeeName }}</span>
              <v-icon class="remove-icon" @click="removeUser(i)">mdi-close-circle</v-icon>
            </div>
            <div class="mt-2" v-for="(depart, i) in selectedDepartment" :key="depart.id" style="position:relative;">
              <v-avatar size="30" class="mx-2" color="#E65100">{{ depart.departmentOaName?.charAt(0).toUpperCase() }}</v-avatar>
              <span class="text-capitalize">{{ depart.departmentOaName }}</span>
              <v-icon class="remove-icon" @click="removeDepart(i)">mdi-close-circle</v-icon>
            </div>
          </div>
        </v-flex>
      </v-layout>
      <v-divider></v-divider>
      <div class="d-flex justify-end px-4 py-2">
        <v-spacer></v-spacer>
        <v-btn class="close mr-4" text @click="closeModal" outlined depressed>取消</v-btn>
        <v-btn class="save" text @click="saveSelectedData" depressed>确定</v-btn>
      </div>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import {
  mapActions,
  mapGetters
} from "vuex";
export default {
  name: 'UserDepartmentTeamPicker',
  data() {
    return {
      dialog: false,
      tab: null,
      search: "",
      selectedUser: [],
      selectedDepartment: [],
      departAndUser: [],
    }
  },
  computed: {
    ...mapGetters("user", ["users"]),
    ...mapGetters("department", ["departTree", "departments"]),
    filteredUserList() {
      return this.users.filter(user => {
        if (user.employeeName.toLowerCase().includes(this.search)) {
          return user
        }
      });
    },
    filteredDepartList() {
      return this.departments.filter(depart => {
        if (depart.departmentOaName.toLowerCase().includes(this.search)) {
          return depart
        }
      });
    },
    selectedUserCount() {
      return this.selectedUser.length;
    },
    selectedDepartCount() {
      return this.selectedDepartment.length;
    }
  },
  methods: {
    ...mapActions("user", ["getUsers"]),
    ...mapActions("department", ["getDepartments", "getAllDepartments"]),
    //...mapGetters("user", ["users"]),
   // ...mapGetters("department", [ "departments"]),
    openDialog(visibleRange=null) {
      this.dialog = true;
      this.selectedUser = [];
      this.selectedDepartment = [];
      this.departAndUser = [Object.assign({}, this.departTree[0])];
      const addMembers = (items) => {
        for(let i=0;i<items.length;i++) {
          if(!items[i].dp_departments) {
            items[i].children = this.users.filter(user => user.deptId == items[i].id);
          } else {
            addMembers(items[i].dp_departments);
            items[i].children = this.users.filter(user => user.deptId == items[i].id).concat(items[i].dp_departments)
          }         
        }
      }
      addMembers(this.departAndUser);
      if(visibleRange) {
        const tempArray = visibleRange.split(',');
        tempArray.filter(id => {
            if(id.includes('u')) {
              const index = this.users.findIndex(u => u.id == id.substring(1));
              this.selectedUser.push( {id:id.substring(1),employeeName:this.users[index].employeeName}); //用户筛选列表数组中存放id与员工名字
            } else if (id.includes('d')) {
              const index = this.departments.findIndex(u => u.id == id.substring(1));
               this.selectedDepartment.push({id:id.substring(1),departmentOaName:this.departments[index].departmentOaName});  //部门列表数组放入id与部门名字
            } else {
              if(id !== '') {
              const index = this.users.findIndex(u => u.id == id);
              if(index > -1) {
                this.selectedUser.push({id:id,employeeName:this.users[index].employeeName}) //{id:user.id,employeeName:user.employeeName}
              }
              }              
            }
        });
      }
    },
    selectDepartment(department) {
      const index = this.selectedDepartment.findIndex(d => d.id == department.id);
      if(index == -1) {
        this.selectedDepartment.push({id:department.id,departmentOaName:department.departmentOaName});//{id:department.id,departmentOaName:department.departmentOaName}
      }
    },
    selectUser(user) {
      const index = this.selectedUser.findIndex(u => u.id == user.id);
      if(index == -1) {
        this.selectedUser.push({id:user.id,employeeName:user.employeeName}) //{id:user.id,employeeName:user.employeeName}
      }
    },
    stopParentEvent(ev) {
      ev.stopPropagation();
    },
    closeModal() {
      this.dialog = false;
      this.search = "";
    },
    saveSelectedData() {
      let param = {
        user: this.selectedUser,
        depart: this.selectedDepartment
      }
      this.$emit('selected', param)
      this.dialog = false;
      this.search = "";
      this.selectedUser = [];
      this.selectedDepartment = [];
    },
    removeUser(index) {
      this.selectedUser.splice(index, 1);
    },
    removeDepart(index) {
      this.selectedDepartment.splice(index, 1);
    }
  },
  mounted() {
    this.getUsers();
    this.getDepartments();
    this.getAllDepartments();
  }
}
</script>

<style scoped>
#tab-1 {
  max-height: 400px;
  overflow-y: scroll;
}

#tab-2 {
  max-height: 400px;
  overflow-y: scroll;
}

.v-input__slot {
  min-height: 36px !important;
}

.picker-content .flex {
  max-width: 50%;
}

.label {
  font-size: 14px;
  font-weight: 400;
  color: #3f4755;
  line-height: 22px;
  width: 100%;
}

.selected {
  min-height: 300px;
}

.selected>div:hover .remove-icon {
  display: inline-block;
  cursor: pointer;
  color: red;
}

.remove-icon {
  font-size: 14px;
  position: absolute;
  display: none;
}

.searched {
  min-height: 290px;
}

.v-text-field__details {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer:hover {
  background: #eee;
}
</style>
